@use '../setting/grid-system' as *;
@use '../function/breakpoint' as func;

@mixin mp-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: func.breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}
