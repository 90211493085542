@use '../global' as *;

.simple-list {
  &__item {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
  &--horizontal {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    padding-left: 20px;
    .simple-list__item {
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
}

.dot-list {
  &__item {
    position: relative;
    padding-left: 16px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '・';
    }
  }
}
