@use '../global' as *;

.global-header {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  min-width: 768px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(#000, 0.1);
  line-height: 1.25;
  z-index: +3;
  &__title {
    flex-shrink: 0;
    display: grid;
    place-items: center;
    width: max(200px, 16%);
    height: 50px;
    padding-block: 8px;
    background: $primary;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
}

.global-header-nav {
  display: flex;
  flex: 1 0 0;
  width: min(568px, 84%);
  padding-inline: clamp(16px, 2.5vw, 32px);
  font-size: 12px;
  &__breadcrumb {
    flex: 1 0 0;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
  }
  &__loginid {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: fit-content;
    padding-inline: clamp(12px, 1.875vw, 24px);
    color: $gray-600;
    font-weight: bold;
  }
  &__btn {
    flex-shrink: 0;
    display: grid;
    place-items: center;
    & > a {
      display: block;
      width: fit-content;
      background: #dcdcdc;
      border: 1px $gray-600 solid;
      border-radius: 4px;
      padding: 4px 8px;
      color: $gray-600;
      font-weight: bold;
      text-decoration: none;
      transition: background 0.3s;
      &:hover {
        @media (hover: hover) {
          background: $gray-200;
        }
      }
    }
  }
}
