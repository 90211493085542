@use '../global' as *;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .d#{$infix}-inline {
      display: inline;
    }
    .d#{$infix}-inline-block {
      display: inline-block;
    }
    .d#{$infix}-block {
      display: block;
    }
    .d#{$infix}-flex {
      display: flex;
    }
    .d#{$infix}-grid {
      display: grid;
    }
    .d#{$infix}-none {
      display: none;
    }
  }
}
