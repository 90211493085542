@use '../global' as *;

// text-align
.ta-center {
  text-align: center;
}
.ta-right {
  text-align: right;
}

// text-decoration
.td-none {
  text-decoration: none;
}
.td-underline {
  text-decoration: underline;
}

// word-break
.break-all {
  word-break: break-all;
}
