@use '../global' as *;

.mail-magazine-file-add-field {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: 24px;
}
.mail_magazine_file_image_field {
  margin-top: 16px;
}
.mail_magazine_file_image_preview_container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .image_preview {
    width: 100%;
  }
}
.image-select {
  .mail_magazine_file_field_file {
    display: none;
  }
}

.mail_magazine_url_field {
  margin-top: 16px;
}
