@use '../global' as *;

.contents-section {
  position: relative;
  margin-bottom: 100px;
  &__title {
    position: sticky;
    top: 70px;
    display: flex;
    column-gap: 8px;
    width: 100%;
    padding: 4px 8px;
    background: #fff;
    border: 1px $gray-300 solid;
    color: $gray-600;
    font-size: 12px;
    font-weight: bold;
    z-index: +2;
    &::before {
      content: attr(data-en);
      text-transform: uppercase;
    }
  }
}

.content-form {
  margin-top: 24px;
  padding: 24px;
  background: #fff;
  border: 1px $gray-300 solid;
}
.content-form-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: 60px auto 32px;
  .preview-alert {
    margin-top: 8px;
  }
}
.content-form-block {
  display: flex;
  column-gap: 32px;
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
  &__label {
    flex-shrink: 0;
    width: clamp(100px, 12.5vw, 160px);
    font-weight: bold;
    text-align: right;
  }
  &__input {
    flex: 1 0 0;
    word-break: break-all;
  }
  &__item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    .input-text {
      display: flex;
      align-items: center;
      column-gap: 8px;
      width: 100px;
    }
  }
}

.content-template-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: 24px;
}
.content-template-list-item {
  padding: 12px 16px;
  border: 1px $gray-300 solid;
  &__label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
}

.node_group {
  margin-top: 10px;
}

.image-add-field {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: 24px;
}
.image-field {
  margin-top: 16px;
}
.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .image-preview {
    width: 100%;
  }
  &--media-assets {
    width: 50%;
  }
}
.image-select {
  width: 100%;
  .field_file {
    display: none;
  }
  &__btn {
    display: grid;
    place-items: center;
    width: 100%;
    padding-block: 8px;
    background: $gray-100;
    border: 1px $gray-300 solid;
    font-size: 12px;
  }
}
.field-order {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: calc(60% - 4px);
  &__label {
    flex-shrink: 0;
    width: fit-content;
    font-size: 12px;
  }
  &__input {
    flex: 1 0 0;
    input {
      width: 100%;
      height: 100%;
      border: 1px $gray-300 solid;
      border-radius: 0;
    }
  }
}
.field-remove {
  width: calc(40% - 4px);
}

.button-add-field {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: 24px;
  row-gap: 16px;
}
.add-btn-button {
  margin-bottom: 16px;
}
.button-input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .button-input {
    width: 100%;
    .input-text {
      margin-bottom: 8px;
    }
  }
}
.button_field-item {
  display: flex;
  column-gap: 16px;
  &__label {
    flex-shrink: 0;
  }
  &__object {
    flex: 1 0 0;
  }
}

.btn-add {
  & + .url-field {
    margin-top: 16px;
  }
}
.url-field {
  & + & {
    margin-top: 16px;
  }
}
.url-field-contents {
  display: flex;
  width: 100%;
  column-gap: 8px;
  .input-text {
    flex: 1 0 0;
  }
  .field-remove {
    flex-shrink: 0;
    width: 100px;
  }
}

.users-number {
  width: fit-content;
  padding: 4px 16px;
  margin-inline: auto;
  background: $primary;
  color: #fff;
  font-size: 14px;
  span {
    font-size: 24px;
    font-weight: bold;
  }
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 80px;
  [class^='btn'] {
    font-size: 16px;
  }
}

.mylibrary-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}
.mylibrary-list-item {
  padding: 16px;
  border: 1px $gray-300 solid;
  &__title {
    margin-bottom: 8px;
    font-weight: bold;
  }
  &__body {
    margin-bottom: 16px;
  }
  &__btn {
    display: flex;
    justify-content: center;
    column-gap: 8px;
  }
}
[data-role='content-display'] {
  .customerconcierge-popup {
    position: relative;
    background: none;
  }
  .customerconcierge-popup-content {
    margin: auto;
    padding: 0;
    border: 1px $gray-300 solid;
    border-radius: 0;
    box-shadow: none;
  }
  .customerconcierge-close {
    display: none;
  }
  .customerconcierge-image {
    width: 100%;
    & + * {
      margin-top: 16px;
    }
  }
  .customerconcierge-title {
    margin-bottom: 8px;
    padding: 0 16px;
    font-size: 20px;
  }
  .customerconcierge-text {
    display: block;
    margin-bottom: 16px;
    padding-inline: 16px;
    text-align: left;
  }
  .customerconcierge-button {
    margin: 0 0 16px;
  }
  .customerconcierge-frame {
    border-color: $gray-300;
  }
  .customerconcierge-block_url {
    text-decoration: none;
  }
}
