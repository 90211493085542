@use '../global' as *;

.cmn-table {
  border: 1px $gray-200 solid;
  &__tr {
    background: none;
  }
  &__th {
    border: none;
    background: none;
    font-size: 12px;
  }
  &__td {
    border: none;
    background: none;
    font-size: 12px;
    &--title {
      font-size: 14px;
      font-weight: bold;
    }
  }
  thead {
    .cmn-table__tr {
      background: #fff;
      border-bottom: 1px $gray-200 solid;
    }
    .cmn-table__th {
      white-space: nowrap;
    }
  }
  tbody {
    .cmn-table__tr {
      &:nth-of-type(2n-1) {
        background: $gray-100;
      }
      &:nth-of-type(2n) {
        background: #fff;
      }
    }
    .cmn-table__th,
    .cmn-table__td {
      padding: 16px 8px;
    }
  }
  a:not([class^='btn']) {
    color: $blue;
  }
}

.show-table {
  border: 1px $gray-200 solid;
  &__tr {
    &:nth-of-type(2n-1) {
      background: $gray-100;
    }
    &:nth-of-type(2n) {
      background: #fff;
    }
  }
  &__th {
    width: 20%;
    padding: 16px;
    background: none;
    border: none;
  }
  &__td {
    padding: 16px;
    border: none;
    background: none;
  }
}
