@use '../global' as *;

.login-page-contents {
  display: grid;
  place-items: center;
  min-height: 100vh;
  padding: 20px;
  background: $thin-green;
}

.login-card {
  min-width: 400px;
  margin: auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(#000, 0.16);
  &__title {
    margin-bottom: 12px;
    color: $gray-700;
    font-size: 20px;
    text-align: center;
  }
  &__btn {
    width: 200px;
    height: 48px;
    margin-inline: auto;
    input {
      width: 100%;
      height: 100%;
      background: $primary;
      border: none;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .main-notice {
    margin-bottom: 8px;
    color: $red;
    font-size: 12px;
    text-align: center;
  }
}

.login-card-fieldset {
  margin-bottom: 16px;
  &__label {
    font-size: 12px;
    font-weight: bold;
  }
  &__input {
    input[type='text'],
    input[type='password'] {
      width: 100%;
      outline: none;
    }
  }
  .show-password {
    display: block;
    width: fit-content;
    margin-left: auto;
    font-size: 12px;
    input {
      margin-right: 4px;
    }
  }
}

.login-card-remember {
  margin-bottom: 16px;
  text-align: center;
}
