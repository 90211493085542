@use '../global' as *;
@use 'sass:math';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 0 through 20 {
      .p#{$infix}-#{$i} {
        margin: math.div($i, 2) * 16px;
      }
      .px#{$infix}-#{$i} {
        margin-inline: math.div($i, 2) * 16px;
      }
      .py#{$infix}-#{$i} {
        margin-block: math.div($i, 2) * 16px;
      }
      .pt#{$infix}-#{$i} {
        margin-top: math.div($i, 2) * 16px;
      }
      .pe#{$infix}-#{$i} {
        margin-right: math.div($i, 2) * 16px;
      }
      .pb#{$infix}-#{$i} {
        margin-bottom: math.div($i, 2) * 16px;
      }
      .ps#{$infix}-#{$i} {
        margin-left: math.div($i, 2) * 16px;
      }
    }
  }
}
