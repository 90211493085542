@use '../global' as *;

.contents-sort {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 20px;
  margin-bottom: 20px;
  &:has(.contents-sort-block) {
    column-gap: 32px;
  }
}
.contents-sort-block {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  column-gap: 12px;
  &__title {
    font-size: 14px;
  }
  &__btns {
    display: flex;
    column-gap: 12px;
  }
}
