@use '../global' as *;

// テキスト色
@each $color, $value in $theme-colors {
  .text-#{$color} {
    color: $value;
  }
}

// weight
.fw-bold {
  font-weight: bold;
}
.fw-normal {
  font-weight: normal;
}

// size
@for $i from 10 through 20 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
}
