@use '../global' as *;

.image-preview {
  width: 150px; /* プレビュー表示エリアの幅 */
  height: auto; /* 高さは自動調整 */
}

.image_preview {
  width: 150px; /* プレビュー表示エリアの幅 */
  height: auto; /* 高さは自動調整 */
}
