@use '../global' as *;

.sidemenu {
  position: relative;
  flex-shrink: 0;
  width: max(200px, 16%);
  padding-bottom: 32px;
  background: $light-green;
  .copyright {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 16px;
    color: $green;
    font-size: 12px;
    text-align: center;
  }
}

.sidemenu-list {
  position: sticky;
  top: 50px;
  border: 1px $gray-200 solid;
  border-bottom: none;
  &__item {
    background: #fff;
    border-bottom: 1px $gray-200 solid;
    & > a {
      position: relative;
      display: block;
      padding: 14px 16px;
      background: #fff;
      font-weight: bold;
      text-decoration: none;
      transition: background 0.3s;
      &::before {
        position: absolute;
        top: 50%;
        right: 16px;
        display: block;
        width: 8px;
        height: 8px;
        border-right: 1px #000 solid;
        border-bottom: 1px #000 solid;
        content: '';
        transform: translateY(-50%) rotate(-45deg);
        transition: right 0.3s;
      }
      &:hover {
        @media (hover: hover) {
          background: $gray-100;
        }
        &::before {
          @media (hover: hover) {
            right: 12px;
          }
        }
      }
    }
    .no-link {
      display: block;
      padding: 8px 16px;
      background: #fff;
      color: $gray-200;
    }
  }
}
