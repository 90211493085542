$white: #fff;
$black: #000;

$green: #2fa09d;
$light-green: #b8dbda;
$thin-green: #eff7f7;
$blue: #2782ad;
$red: #f43f5e;

$gray-100: #f6f6f6;
$gray-200: #ddd;
$gray-300: #ccc;
$gray-400: #bbb;
$gray-500: #999;
$gray-600: #666;
$gray-700: #333;

$primary: $green;

$color-text: #646464;
$color-link: #646464;

$theme-colors: (
  'white': $white,
  'black': $black,
  'primary': $primary,
  'blue': $blue,
  'red': $red,
  'gray': $gray-500,
);
