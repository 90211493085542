@use '../global' as *;

html {
  scroll-behavior: smooth;
  scroll-padding-top: 50px;
  min-width: 768px;
}

body {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  min-height: 100vh;
  padding-top: 50px;
  background: #fff;
  color: $color-text;
  line-height: 1.75;
  &.login-page {
    padding-top: 0;
  }
}

img {
  height: auto;
}

button {
  box-sizing: border-box;
}

a {
  color: $color-link;
}

code {
  display: inline-block;
  margin-inline: 4px;
  padding: 4px 8px;
  background: $gray-100;
  border-radius: 4px;
  color: $gray-600;
  font-size: 12px;
  line-height: 1;
  vertical-align: middle;
}
