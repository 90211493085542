@use '../global' as *;

.input-text {
  input[type='text'],
  input[type='number'] {
    width: 100%;
    padding: 4px 8px;
    border: 1px $gray-300 solid;
    line-height: 1.5;
  }
  input[type='datetime-local'] {
    padding: 4px 8px;
    border: 1px $gray-300 solid;
    line-height: 1.5;
  }
  textarea {
    width: 100%;
    min-height: 4.5em;
    padding: 4px 8px;
    border: 1px $gray-300 solid;
    line-height: 1.5;
    overflow-anchor: none;
  }
}

.example-text {
  margin-top: 4px;
  color: $gray-500;
  font-size: 12px;
}
