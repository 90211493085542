@use '../global' as *;

#error_explanation {
  margin-bottom: 30px;
  padding: 20px;
  background: #fff;
  border: 1px $red solid;
  h2 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
  }
  ul {
    li {
      color: $red;
      font-size: 14px;
    }
  }
}

.form-contents {
  &__btn {
    width: 200px;
    height: 48px;
    margin-top: 64px;
    margin-inline: auto;
    input {
      width: 100%;
      height: 100%;
      background: $primary;
      border: none;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.form-contents-block {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &__title {
    flex-shrink: 0;
    width: 150px;
    font-size: 14px;
    font-weight: bold;
    .field_with_errors {
      color: $red;
    }
  }
  &__input {
    flex-grow: 1;
  }
}

.select-wrap {
  width: fit-content;
  background: #fff;
  border: 1px $gray-200 solid;
  select {
    background: none;
    border: none;
    margin: 0;
    padding: 8px;
  }
}
.input-txt {
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    width: 100%;
    padding: 4px;
    border: 1px $gray-200 solid;
    outline: none;
  }
  .field_with_errors {
    input {
      border-color: $red;
    }
  }
}
