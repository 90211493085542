@use '../global' as *;
@use 'sass:math';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mp-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @for $i from 0 through 20 {
      .m#{$infix}-#{$i} {
        margin: math.div($i, 2) * 16px;
      }
      .mx#{$infix}-#{$i} {
        margin-inline: math.div($i, 2) * 16px;
      }
      .my#{$infix}-#{$i} {
        margin-block: math.div($i, 2) * 16px;
      }
      .mt#{$infix}-#{$i} {
        margin-top: math.div($i, 2) * 16px;
      }
      .me#{$infix}-#{$i} {
        margin-right: math.div($i, 2) * 16px;
      }
      .mb#{$infix}-#{$i} {
        margin-bottom: math.div($i, 2) * 16px;
      }
      .ms#{$infix}-#{$i} {
        margin-left: math.div($i, 2) * 16px;
      }
      .mx#{$infix}-auto {
        margin-inline: auto;
      }
      .me#{$infix}-auto {
        margin-right: auto;
      }
      .ms#{$infix}-auto {
        margin-left: auto;
      }
    }
  }
}
