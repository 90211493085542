@use '../global' as *;

@for $i from 1 through 5 {
  .line-clamp-#{$i} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$i};
    overflow: hidden;
  }
}
