@use '../global' as *;

.main {
  flex: 1 0 0;
  width: min(568px, 84%);
}

.main-contents {
  height: 100%;
  padding: 20px 30px;
  background: #fcfcfc;
  .main-notice {
    margin-bottom: clamp(16px, 1.875vw, 24px);
    color: $red;
    font-size: 12px;
  }
}

.main-contents-header {
  margin-bottom: clamp(16px, 1.875vw, 24px);
  @include mp-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }
  &__title {
    font-size: 24px;
    @include mp-breakpoint-up(lg) {
      flex-grow: 1;
      margin-right: 12px;
    }
  }
}

.main-contents-header-nav {
  @include mp-breakpoint-up(md) {
    display: flex;
    align-items: center;
    gap: clamp(8px, 1.25vw, 16px);
  }
}
.main-contents-header-search {
  display: flex;
  column-gap: 8px;
  &__input {
    width: 180px;
    height: 36px;
    input {
      width: 100%;
      padding: 1px 8px;
      background: #fff;
      border: 1px $gray-300 solid;
      font-size: 16px;
      line-height: 2;
    }
  }
  &__btn {
    display: grid;
    place-items: center;
    width: 36px;
    height: 36px;
    background: $blue;
    border-radius: 2px;
    color: #fff;
  }
}
