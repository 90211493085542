/* app/assets/stylesheets/tasks.css */
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd; /* ボーダーを追加 */
  padding: 8px; /* セルの余白を調整 */
  text-align: left; /* セル内のテキストを左寄せに */
}

th {
  background-color: #f2f2f2; /* ヘッダーの背景色を設定 */
}

.header-buttons {
  display: flex;
  /* justify-content: space-between; */ /* 子要素を水平方向に均等に配置 */
  align-items: center; /* 子要素を垂直方向に中央揃え */
}

#showTableButton {
  margin-right: 10px; /* 一覧表示ボタンの右側に10pxの間隔を設定 */
}
