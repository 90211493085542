@use '../global' as *;

.btn-create {
  position: relative;
  display: grid;
  place-items: center;
  width: fit-content;
  height: 36px;
  padding: 4px 16px 4px 36px;
  background: #fff;
  border: 1px $blue solid;
  border-radius: 4px;
  color: $blue;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $blue;
      color: #fff;
    }
  }
}

.btn-mylibrary {
  display: grid;
  place-items: center;
  width: fit-content;
  height: 36px;
  padding: 4px 16px;
  background: $blue;
  border: 1px $blue solid;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #fff;
      color: $blue;
    }
  }
}

.btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  width: fit-content;
  padding: 4px 8px;
  background: #fff;
  border: 1px $blue solid;
  border-radius: 4px;
  color: $blue;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  .icon {
    width: 12px;
  }
  &:hover {
    @media (hover: hover) {
      background: $blue;
      color: #fff;
    }
  }
}

.btn-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: 100%;
  height: 100%;
  border: 1px $gray-300 solid;
  font-size: 12px;
}

.btn-clear,
.btn-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: fit-content;
  padding: 4px 8px;
  background: #fff;
  border: 1px $gray-300 solid;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none;
  transition: background 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $gray-200;
    }
  }
}

.btn-save,
.btn-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: fit-content;
  padding: 4px 8px;
  background: $blue;
  border: 1px $blue solid;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #fff;
      color: $blue;
    }
  }
}

.btn-copy,
.btn-list {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: fit-content;
  padding: 4px 8px;
  background: #fff;
  border: 1px $blue solid;
  border-radius: 4px;
  color: $color-link;
  font-size: 12px;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  .icon {
    color: $blue;
    transition: color 0.3s;
  }
  &:hover {
    @media (hover: hover) {
      background: $blue;
      color: #fff;
    }
    .icon {
      @media (hover: hover) {
        color: #fff;
      }
    }
  }
}

.btn-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: 100%;
  padding: 8px 16px;
  background: $primary;
  border: 1px $primary solid;
  border-radius: 4px;
  color: #fff;
  transition: background 0.3s, color 0.3s;
  &:hover {
    @media (hover: hover) {
      background: #fff;
      color: $primary;
    }
  }
}

.btn-sort {
  display: grid;
  place-items: center;
  width: fit-content;
  padding: 2px 6px;
  background: #f6f6f6;
  border: 1px #dcdcdc solid;
  border-radius: 2px;
  font-size: 14px;
  text-decoration: none;
  transition: background 0.3s;
  &:hover {
    @media (hover: hover) {
      background: $light-green;
    }
  }
  &.is-current {
    background: $light-green;
  }
}

.operation-btn-list {
  display: flex;
  column-gap: 8px;
  [class^='btn-'] {
    position: relative;
    display: block;
    width: fit-content;
    padding: 2px 4px 2px 20px;
    border-radius: 4px;
    font-size: 12px;
    text-decoration: none;
    white-space: nowrap;
    .icon-pos-l {
      left: 4px;
    }
    .icons {
      height: 12px;
    }
  }
  .btn-detail {
    background: $blue;
    border: 1px $blue solid;
    color: #fff;
    transition: background 0.3s, color 0.3s;
    &:hover {
      @media (hover: hover) {
        background: #fff;
        color: $blue;
      }
    }
  }
  .btn-delete {
    background: #fff;
    border: 1px $gray-600 solid;
    color: $gray-600;
    transition: background 0.3s, color 0.3s;
    &:hover {
      @media (hover: hover) {
        background: $gray-600;
        color: #fff;
      }
    }
  }
  .btn-copy {
    .icon-pos-l {
      color: $blue;
      transition: color 0.3s;
    }
    &:hover {
      .icon-pos-l {
        @media (hover: hover) {
          color: #fff;
        }
      }
    }
  }
}
